@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Palanquin:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Palanquin:wght@100;200;300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

@layer components {
    .max-container {
        max-width: min(90%,1440px);
        margin: 0 auto;
    }

    .input {
        @apply sm:flex-1 max-sm:w-full text-base leading-normal text-slate-gray pl-5 max-sm:p-5 outline-none sm:border-none border max-sm:border-slate-gray max-sm:rounded-full;
    }
}

@layer utilities {
    .padding {
        @apply sm:px-16 px-8 sm:py-24 py-12;
    }

    .padding-x {
        @apply sm:px-16 px-8;
    }

    .padding-y {
        @apply sm:py-24 py-12;
    }

    .padding-l {
        @apply sm:pl-16 pl-8;
    }

    .padding-r {
        @apply sm:pr-16 pr-8;
    }

    .padding-t {
        @apply sm:pt-24 pt-12;
    }

    .padding-b {
        @apply sm:pb-24 pb-12;
    }

    .info-text {
        @apply font-montserrat text-slate-gray text-lg leading-7;
    }
    .row-gap-0 { row-gap: 0; }
    .row-gap-1 { row-gap: 0.25rem; }
    .row-gap-2 { row-gap: 0.5rem; }
    .row-gap-3 { row-gap: 0.75rem; }
    .row-gap-4 { row-gap: 1rem; }
    .row-gap-5 { row-gap: 1.25rem; }
    .row-gap-6 { row-gap: 1.5rem; }
    .row-gap-8 { row-gap: 2rem; }
    .row-gap-10 { row-gap: 2.5rem; }
    .row-gap-12 { row-gap: 3rem; }
    .row-gap-16 { row-gap: 4rem; }
    .row-gap-20 { row-gap: 5rem; }
    .row-gap-24 { row-gap: 6rem; }
    .row-gap-32 { row-gap: 8rem; }
    .row-gap-40 { row-gap: 10rem; }
    .row-gap-48 { row-gap: 12rem; }
    .row-gap-56 { row-gap: 14rem; }
    .row-gap-64 { row-gap: 16rem; }
    .row-gap-px { row-gap: 1px; }
}